import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';

import QseFollower from './router/QseFollower.js';

function App() {

    return (
        <div className="App nanumsquare">
            <Routes>
                <Route path='/widgets/follower' element={[<QseFollower/>]}></Route>
            </Routes>
        </div>
    );
}

export default App;
